import TableContainer from "components/Common/TableContainer";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { Alert, Button, Modal } from "reactstrap";
import { getPerizie, nuovoPerito } from "store/actions";
import { getPerizieNoStore } from '../../helpers/elba_helper';
// import { deletePerito, ottieniPeriti } from "store/users/actions";
// import NewUserModal from "./newUserModal";
import pdfImage from "../../assets/images/pdf-icon.png";
import mapsImage from "../../assets/images/maps-icon.png";
import moment from "moment";

const ListPerizie = ({certificato,agenzia,consorzio,emptyPeriziaWarning}) => {

    const [selectRemovedItem, setSelectedRemovedItem] = useState();
    const fetchObj = useRef();
    const [dataPerizieNoStore,setDataPerizieNoStore] = useState([])
    
    const columns = useMemo(
        () => [
            {
                Header: 'DATA',
                accessor: 'data_perizia',
                disableFilters: true,
                Cell: ({cell}) => {
                    return <div>{moment(cell?.value).format('DD-MM-YYYY')}</div>;
                },
            },
            {
                Header: 'PERITO',
                accessor: 'codice_perito',
            },
            {
                Header: 'PIANTA',
                accessor: 'pianta',
                disableFilters: true,
            },
            {
                Header: 'Assicurato',
                accessor: 'assicurato',
                disableFilters: true,
            },
            {
                Header: 'Certificato',
                accessor: 'nContratto',
                disableFilters: true,
            },
            {
                Header: 'Partita',
                accessor: 'partita',
                disableFilters: true,
            },
            {
                Header: 'LUOGO',
                accessor: 'luogo',
                disableSortBy: true,
                disableFilters: true,
                Cell: ({cell}) => {
                    return (
                        <a href={`http://www.google.com/maps/place/${cell?.value}`} target="_blank" rel="noreferrer">
                            <img style={{height:40}} src={mapsImage} />
                        </a>
                    );
                },
            },
            {
                Header: 'PDF',
                accessor: 'pdf',
                disableSortBy: true,
                disableFilters: true,
                Cell: ({cell}) => {
                    //modifica per puntare alla directory public
                    return (
                        <a href={cell?.value.replace('app/public/', '')} target="_blank" rel="noreferrer">
                            <img style={{height:40}} src={pdfImage} />
                        </a>
                    );
                },
            },
            {
                Header: 'AZIONI',
                accessor: '',
                disableSortBy: true,
                disableFilters: true,
                Cell: (({cell}) => {
                    return <div>
                        <button
                            onClick={() => setSelectedRemovedItem(cell?.row?.original?.id)}
                            type="button"
                            className="btn btn-danger"
                          >
                            <i className="mdi mdi-trash-can"></i>
                          </button>
                    </div>
                })
            },
        ],
    []);

    const dispatch = useDispatch();
    const { perizieDataPaginate } = useSelector(state => ({
        perizieDataPaginate: state.Perizie?.perizieDataPaginate,
        // deletePeritoStatus: state.Users?.deletePeritoStatus,
        // newPeritoStatus: state.Admin?.newPeritoStatus,
    }));

    const giveMePerizie = async () => {
        const resp = await getPerizieNoStore()
        let dataFiltered = resp.data.data.filter(e => e.certificato == certificato?.props?.value && e.agenzia == agenzia?.props?.value && e.consorzio == consorzio?.props?.value)
        if(!dataFiltered.length && certificato?.props?.value) {
            emptyPeriziaWarning()
        }else {
            setDataPerizieNoStore(dataFiltered)
        }
        
    }

    
    useEffect(()=> {
        giveMePerizie()
    },[])

    useEffect(() => {
        console.log("perizieDataPaginate",perizieDataPaginate);
    },[perizieDataPaginate]);





    const fetchData = useCallback(({ pageSize, pageIndex, sortBy, filters }) => {
        const query  = {"paginate":true, page:pageIndex+1};

        if(sortBy && sortBy?.length){
            const filterSortBy = sortBy.filter(sortBbj => (sortBbj?.id !== null && sortBbj?.id !== undefined));
            filterSortBy.forEach(sortObj => {
                query[sortObj.id] = !sortObj.desc ? 'asc' : 'desc';
            });
        }
        
        if(filters && filters?.length){
            query.text = filters[0].value;
        }

        fetchObj.current = query;
        dispatch(getPerizie(query));
    },[]);

    const deleteItem = () => {
        console.log("----selectRemovedItem",selectRemovedItem);
        // dispatch(deletePerito(selectRemovedItem))
    }

    const createUserCallback = (values) => {
        // dispatch(nuovoPerito(values));
    }

    // useEffect(() => {
    //     if(deletePeritoStatus || newPeritoStatus){
    //         if(fetchObj.current){
    //             setSelectedRemovedItem();
    //             setNewItemModalOpen(false);
    //             dispatch(getPerizie(fetchObj.current));
    //         }
    //     }
    // },[deletePeritoStatus, newPeritoStatus]);

    return(
        <>
        {(certificato|| agenzia || consorzio) && dataPerizieNoStore.length ?
         
                <TableContainer
                columns={columns}
                data={dataPerizieNoStore || []}
                fetchData={fetchData}
                controlledPageCount={perizieDataPaginate?.last_page}
                customPageSizeOptions
                customPageSize={10}
                className="custom-header-css"
            /> :
        <>
            <TableContainer
                columns={columns}
                data={perizieDataPaginate?.data || []}
                fetchData={fetchData}
                controlledPageCount={perizieDataPaginate?.last_page}
                customPageSizeOptions
                customPageSize={10}
                className="custom-header-css"
            />
             <Modal
                isOpen={selectRemovedItem ? true : false}
                backdrop={'static'}
                id="staticBackdrop"
            >
                <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">Elimina Perizia</h5>
                <button type="button" className="btn-close"
                    onClick={() => {
                        setSelectedRemovedItem();
                    }} aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <h2>Sei sicuro?</h2>
                    <p>Se si conferma, il sistema eliminerà la perizia.</p>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-light" onClick={() => {
                        setSelectedRemovedItem();
                    }}>Annulla</button>
                    <button type="button" onClick={deleteItem} className="btn btn-danger">Procedi elimina Perizia</button>
                </div>
            </Modal> </>
}
            {/*<NewUserModal isOpen={newItemModalOpen} closeModal={() => setNewItemModalOpen(false)} createUserCallback={createUserCallback} /> */}
        </>
    )
}

export default ListPerizie